import { Description } from "components/homePage/packagesSection/description/Description";
import { PreConfiguredPackages } from "components";

import { Section } from "./PackagesSection.styled";

export function PackagesSection({
    packages
}: {
    packages?: Array<MagentoProduct>;
}): JSX.Element {
    return (
        <Section>
            <Description />
            <PreConfiguredPackages packages={packages} hasDarkTheme />
        </Section>
    );
}
