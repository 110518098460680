import styled from "styled-components";

import { Icon as BaseIcon, Link as BaseLink } from "components";

import { media } from "constants/media";

export const Section = styled.section`
    background-color: var(--color-green-50);
    color: var(--color-beige-50);

    padding: 64px 0;

    ${media.lgUp} {
        padding: 92px 0 32px;
    }
`;

export const Container = styled.div`
    margin: auto;
    max-width: 1200px;

    ${media.mdUp} {
        display: grid;
        grid-template-areas: "header decoration" "description decoration";
        grid-template-columns: 1fr 1fr;
        width: 93%;
    }

    ${media.lgUp} {
        column-gap: 10%;
    }

    ${media.xlUp} {
        column-gap: 205px;
    }
`;

export const Header = styled.header`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 85%;

    ${media.mdUp} {
        grid-area: header;
    }

    ${media.lgUp} {
        margin-left: 104px;
    }

    ${media.xlUp} {
        width: auto;
    }
`;

export const Title = styled.h2`
    display: flex;
    font-family: var(--font-secondary);
    font-size: 34px;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 32px;

    ${media.mdUp} {
        font-size: 56px;
        line-height: 64px;
        margin-bottom: 0;
    }
`;

export const HandwrittenText = styled.span`
    width: 98px;

    ${media.mdUp} {
        width: 127px;
    }
`;

export const DecorationContainer = styled.div`
    align-self: center;

    ${media.mdUp} {
        grid-area: decoration;
        max-width: 353px;
    }

    ${media.xlUp} {
        margin-right: 0;
    }
`;

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
    margin: 35px auto 0;
    width: 85%;

    > p,
    > a {
        &:not(:last-child) {
            margin-bottom: 32px;
        }
    }

    ${media.mdUp} {
        font-size: 18px;
        grid-area: description;
        line-height: 32px;
        margin: 32px 0 0 95px;
        width: 65%;
    }

    ${media.lgUp} {
        margin: 32px 0 0 168px;
        width: 408px;

        span {
            font-size: 18px;
        }
    }
`;

export const Icon = styled(BaseIcon)`
    margin-left: 13px;
`;

export const Link = styled(BaseLink)`
    width: fit-content;
`;
