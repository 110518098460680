import styled from "styled-components";

import { media } from "constants/media";

export const Section = styled.section`
    background-color: var(--color-green-50);
    color: var(--color-beige-50);
    padding: 64px 0;

    ${media.lgUp} {
        margin-top: 80px;
        padding: 92px 0 32px;
    }
`;
