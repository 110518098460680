import Image from "next/image";

import { ARROW_RIGHT } from "components";

import {
    Container,
    DecorationContainer,
    DescriptionContainer,
    HandwrittenText,
    Header,
    Icon,
    Link,
    Title
} from "./Description.styled";

import { useMediaQueryContext } from "helpers/hooks";

export function Description(): JSX.Element {
    const { isMobileView } = useMediaQueryContext();

    return (
        <Container>
            <Header>
                <Title>
                    Pakketten
                    <HandwrittenText>
                        <Image
                            alt="Easy peasy"
                            height={48}
                            src="/images/easy-peasy.svg"
                            width={127}
                        />
                    </HandwrittenText>
                </Title>
                {isMobileView && (
                    <DecorationContainer>
                        <Image
                            alt="product icons"
                            height={180}
                            src="/images/productsIconsGroup.png"
                            width={195}
                        />
                    </DecorationContainer>
                )}
            </Header>
            {!isMobileView && (
                <DecorationContainer>
                    <Image
                        alt="product icons"
                        height={325}
                        src="/images/productsIconsGroup.png"
                        width={353}
                    />
                </DecorationContainer>
            )}
            <DescriptionContainer>
                <p>
                    Keuzes maken. Ook hierin komen we jouw gemak tegemoet zodat
                    jij niet als een kip zonder kop hoeft te gaan zoeken. Onze
                    Bonte Boxen bieden jou een de optie om in één keer een
                    gevarieerd pakket te kiezen. Wel zo makkelijk.
                </p>
                <Link variant="orange" href="/kip-bestellen#pakketten" passHref>
                    <>
                        <span>Bekijk alle Bonte Boxen</span>
                        <Icon icon={ARROW_RIGHT} size={24} fill="none" />
                    </>
                </Link>
                <Link variant="orange" href="/kip-bestellen" passHref>
                    <>
                        <span>Zelf samenstellen</span>
                        <Icon icon={ARROW_RIGHT} size={24} fill="none" />
                    </>
                </Link>
            </DescriptionContainer>
        </Container>
    );
}
